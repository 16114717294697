<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <a-btn-crypt-code />
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
      <v-btn color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn></template
    >
    <portal to="v-main">
      <edit-dialog v-if="showEditDialog" v-model="showEditDialog" :id="idEdit" :api="url" />
    </portal>
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="true" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
import { getAccess, keyDetect, doubleClickDetect, genModel } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel, doubleClickDetect, keyDetect],
  components: {
    editDialog: () => import("./dialogs/cryptDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.crypt,
      url: "/mechti/crypt_data",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: this.getAccess("cabinet.myData.viewAll") || true ? {} : { createdby_id: this.$root.profile.id },
      },
    };
  },
  created() {
    this.$root.title = this.$route.meta.title || "Мои данные";
  },
  computed: {
    model() {
      let res = this.getModelList(this.m);
      console.log("model", res);
      return res;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
